import { combineReducers } from 'redux'
import { FileTypes } from '../../action_types'

import { handleRequest, initialRequestState } from './helpers'

function exportFiles(state = initialRequestState(), action) {
  return handleRequest(
    FileTypes.EXPORT_FILES_REQUEST,
    FileTypes.EXPORT_FILES_SUCCESS,
    FileTypes.EXPORT_FILES_FAIL,
    state,
    action
  )
}

export default combineReducers({
  exportFiles,
})

import { combineReducers } from 'redux'
import auth from './auth'
import folder from './folder'
import account from './account'
import api_key from './api_key'
import webhook from './webhook'
import submission from './submission'
import org from './org'
import template from './template'
import billing from './billing'
import transaction from './transaction'
import user from './user'
import input_email from './input_email'
import registration from './registration'
import bv_document from './bv_document'
import datapoint from './datapoint'
import file from './file'
import submission_type from './submission_type'
import field_data from './field_data'
import statistics from './statistics'
import billing_policy from './billing_policy'
import payment from './payment'
import order from './order'

export default combineReducers({
  auth,
  folder,
  account,
  api_key,
  webhook,
  submission,
  org,
  template,
  billing,
  transaction,
  user,
  input_email,
  registration,
  bv_document,
  datapoint,
  file,
  submission_type,
  field_data,
  statistics,
  billing_policy,
  payment,
  order,
})

import { combineReducers } from 'redux'
import { BVPageTypes } from '../../action_types'
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers'

function images(state = {}, action) {
  switch (action.type) {
    case BVPageTypes.GET_BV_PAGE_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.data }
    default:
      return state
  }
}

export default combineReducers({
  images,
})

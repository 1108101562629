import { combineReducers } from 'redux'
import { BillingPolicyTypes } from '../../action_types'
import { insertItem } from 'src/utils/helpers'
import moment from 'moment'
import { arrayToObject, removeItem, updateObjectInArray } from 'src/utils/helpers'

function all(state = {}, action) {
  switch (action.type) {
    case BillingPolicyTypes.GET_BILLING_POLICIES_SUCCESS:
      return { ...state, ...arrayToObject(action.data) }
    case BillingPolicyTypes.GET_BILLING_POLICY_SUCCESS:
    case BillingPolicyTypes.CREATE_BILLING_POLICY_SUCCESS:
    case BillingPolicyTypes.UPDATE_BILLING_POLICY_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      }
    case BillingPolicyTypes.DELETE_BILLING_POLICY_SUCCESS:
      const { [action.id]: _, ...newState } = state
      return newState
    default:
      return state
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case BillingPolicyTypes.GET_BILLING_POLICIES_SUCCESS:
      return action.data
    case BillingPolicyTypes.CREATE_BILLING_POLICY_SUCCESS:
      return insertItem(state, action.data)
    case BillingPolicyTypes.UPDATE_BILLING_POLICY_SUCCESS:
      return updateObjectInArray(state, action.data)
    case BillingPolicyTypes.DELETE_BILLING_POLICY_SUCCESS:
      return removeItem(state, action.id)
    default:
      return state
  }
}

function org_billing(state = {}, action) {
  switch (action.type) {
    case BillingPolicyTypes.GET_ORG_BILLING_SUCCESS:
    case BillingPolicyTypes.UPDATE_ORG_BILLING_SUCCESS:
    case BillingPolicyTypes.UPDATE_ORG_BILLING_PERIOD_SUCCESS:
      return {
        ...state,
        [action.org_id]: action.data,
      }
    default:
      return state
  }
}

export default combineReducers({
  all,
  ui,
  org_billing,
})

import axios from 'axios'
import { AuthTypes, CommonTypes } from './docbase/action_types'

const errorStatus = [403, 500]
const notHas404Label = ['data', 'page', 'org', 'folder']

export function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const { types, callAPI, required = [], shouldCallAPI = () => true, payload = {} } = action

    if (!types) {
      // Normal action: pass it on
      return next(action)
    }

    if (!Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string')) {
      throw new Error('Expected an array of three string types.')
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.')
    }

    if (required.some(v => !v)) {
      return
    }

    if (!shouldCallAPI(getState())) {
      return
    }

    const [requestType, successType, failureType] = types

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      })
    )

    return callAPI()
      .then(response => {
        return dispatch(
          Object.assign({}, payload, {
            data: response ? response.data : null,
            type: successType,
          })
        )
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return
        }
        const err = error.response?.data
        const status = error.response?.status

        // if (err && err.code === 'permission_denied') {
        //   return dispatch({ type: AuthTypes.LOGOUT_SUCCESS });
        // }

        if (err && err.code === 'not_authenticated' && err.message === 'Not authenticated') {
          return dispatch({ type: AuthTypes.LOGOUT_SUCCESS })
        }

        if (status === 404 && err.code === 'does_not_exists' && err.label && !notHas404Label.includes(err.label)) {
          dispatch({ type: CommonTypes.SET_STATUS_CODE, data: status })
          setTimeout(() => {
            dispatch({ type: CommonTypes.SET_STATUS_CODE, data: 0 })
          }, 1000)
        }

        if (errorStatus.includes(status) && err.label) {
          dispatch({ type: CommonTypes.SET_STATUS_CODE, data: status })
          setTimeout(() => {
            dispatch({ type: CommonTypes.SET_STATUS_CODE, data: 0 })
          }, 1000)
        }

        if (err && (err.code === 1 || err.code === 2 || err.code === 3) && err.object_name) {
          return dispatch(
            Object.assign({}, payload, {
              error: `${err.code}_${err.object_name}`,
              type: failureType,
            })
          )
        }

        if (err && (err.code === 'unique' || err.code === 'invalid' || err.code === 'blank') && err.label) {
          return dispatch(
            Object.assign({}, payload, {
              error: `${err.code}_${err.label}`,
              type: failureType,
            })
          )
        }

        return dispatch(
          Object.assign({}, payload, {
            error: String(err?.code),
            type: failureType,
          })
        )
      })
  }
}

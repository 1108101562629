import { combineReducers } from 'redux'
import { BVDocumentTypes } from '../../action_types'

import { handleRequest, initialRequestState } from './helpers'

function getBVDocument(state = initialRequestState(), action) {
  return handleRequest(
    BVDocumentTypes.GET_BV_DOCUMENT_REQUEST,
    BVDocumentTypes.GET_BV_DOCUMENT_SUCCESS,
    BVDocumentTypes.GET_BV_DOCUMENT_FAIL,
    state,
    action
  )
}

function validateDocument(state = initialRequestState(), action) {
  return handleRequest(
    BVDocumentTypes.VALIDATE_DOCUMENT_REQUEST,
    BVDocumentTypes.VALIDATE_DOCUMENT_SUCCESS,
    BVDocumentTypes.VALIDATE_DOCUMENT_FAIL,
    state,
    action
  )
}

function verifyDocument(state = initialRequestState(), action) {
  return handleRequest(
    BVDocumentTypes.VERIFY_DOCUMENT_REQUEST,
    BVDocumentTypes.VERIFY_DOCUMENT_SUCCESS,
    BVDocumentTypes.VERIFY_DOCUMENT_FAIL,
    state,
    action
  )
}

function rejectDocument(state = initialRequestState(), action) {
  return handleRequest(
    BVDocumentTypes.REJECT_DOCUMENT_REQUEST,
    BVDocumentTypes.REJECT_DOCUMENT_SUCCESS,
    BVDocumentTypes.REJECT_DOCUMENT_FAIL,
    state,
    action
  )
}

function resetBVDocument(state = initialRequestState(), action) {
  return handleRequest(
    BVDocumentTypes.RESET_DOCUMENT_REQUEST,
    BVDocumentTypes.RESET_DOCUMENT_SUCCESS,
    BVDocumentTypes.RESET_DOCUMENT_FAIL,
    state,
    action
  )
}

function previewImage(state = initialRequestState(), action) {
  return handleRequest(
    BVDocumentTypes.PREVIEW_IMAGE_REQUEST,
    BVDocumentTypes.PREVIEW_IMAGE_SUCCESS,
    BVDocumentTypes.PREVIEW_IMAGE_FAIL,
    state,
    action
  )
}

export default combineReducers({
  getBVDocument,
  validateDocument,
  verifyDocument,
  rejectDocument,
  resetBVDocument,
  previewImage,
})

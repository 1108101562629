import { combineReducers } from 'redux'
import auth from './auth'
import org from './org'
import folder from './folder'
import account from './account'
import api_key from './api_key'
import webhook from './webhook'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import submission from './submission'
import common from './common'
import template from './template'
import billing from './billing'
import transaction from './transaction'
import user from './user'
import input_email from './input_email'
import registration from './registration'
import admin_transaction from './admin_transaction'
import bv_document from './bv_document'
import datapoint from './datapoint'
import file from './file'
import submission_type from './submission_type'
import field_data from './field_data'
import statistics from './statistics'
import billing_policy from './billing_policy'
import payment from './payment'
import order from './order'
import bv_page from './bv_page'

const folderPersistConfig = {
  key: 'folder',
  storage: storage,
  whitelist: ['currentFolder'],
}

const commonPersistConfig = {
  key: 'common',
  storage: storage,
  whitelist: ['previous_route'],
}

const orgPersistConfig = {
  key: 'org',
  storage: storage,
  whitelist: ['currentOrgRole'],
}

export default combineReducers({
  auth,
  org: persistReducer(orgPersistConfig, org),
  account,
  api_key,
  webhook,
  folder: persistReducer(folderPersistConfig, folder),
  submission,
  common: persistReducer(commonPersistConfig, common),
  template,
  billing,
  transaction,
  user,
  input_email,
  registration,
  admin_transaction,
  bv_document,
  datapoint,
  file,
  submission_type,
  field_data,
  statistics,
  billing_policy,
  payment,
  order,
  bv_page,
})
